import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


import { Container, Row, Col, Carousel } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="Island Tours" />

    <Carousel interval={5000}>
      <Carousel.Item>
        <StaticImage
          src="../images/island-tours/01-island-tour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Island Tour"
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/island-tours/02-island-tour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Island Tour"
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/island-tours/03-island-tour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Island Tour"
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/island-tours/04-island-tour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Island Tour"
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/island-tours/05-island-tour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Island Tour"
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/island-tours/06-island-tour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Island Tour"
          
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/island-tours/07-island-tour.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Island Tour"
          
        />
      </Carousel.Item>
      
    </Carousel>
    <div className="hawaiianBG d-flex justify-content-center py-3">
      <h1>Island Tours</h1>
    </div>
    <Container className="p-4">
    <Row className="g-5">
      <Col lg={8}>
        
        <p>
          We offer a wide range of tours from a quick scoot around the island to half day excursions and full day private tours designed to cater to your own personal tastes.
        </p>

        <hr />

        <h2>Experience our Highlights of Rarotonga Tour</h2>
        <p>
          Zip around Rarotonga on an electric tuk tuk with a private guide on this fun-filled adventure in the Cook Islands.  Check out sights like the 19th-century Tinomana Palace, the fragrant Maire Nue Gardens, and the turquoise waters of the Muri Lagoon, and do some shopping at local craft centres.
        </p>
        <p>
          Get picked up by your guide in front of your Rarotonga hotel, where you hop in the back of an electric-powered tuk tuk and head out to explore the island.  Learn about colonial history as you pass Tinomana Palace, look for native plants with a ride by the Maire Nui Botanical Gardens, and find out how coconuts are processed at the Rito Organic Virgin Coconut Oil Factory.
        </p>
        <p>
          At Muri Lagoon, take a moment to dig your toes into the sugar-white sand and then continue on to peek in at the campus of the Takamoa Theological College.  Before your excursion winds to a close, buzz over local arts and craft shops to seek out artisan-created souvenirs, and then cruise back to your accommodation.
        </p>

        <Row>
          <Col lg={6}>
            <p>
              <StaticImage
                src="../images/muri-beach-600x400.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Tuk Tuk on Muri Beach"
              />
            </p>
          </Col>
          <Col lg={6}>
            <p>
              <StaticImage
                src="../images/IMG_2118-600x400.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Tuk Tuk"
              />
            </p>
          </Col>
          
        </Row>
        <p>
          <em>This tour is approximately 3 hours and departs weekday mornings by appointment. Price is $85 per adult, $39 per child (aged 3-12yrs), and infants are free (minimum charge of $200 per tour)</em>
        </p>

        <hr />

        <h2>Create your own Private Tour with a personalised itinerary</h2>

        <p>
          Dive into Rarotonga’s can’t-miss sights and hidden attractions on this private tour of the island by electric tuk tuk. In your open-air ride, cruise around with a knowledgeable guide to see the sights that interest you most, and get tips and insider information on the best beaches and restaurants along the way.
        </p>

        <p>
          Start your private tour with convenient pick-up at your hotel. Hop into the back of an environmentally friendly tuk tuk, and then head out to zip around the island. Chat with your guide about the types of places you’d like to see, or leave the itinerary up to their expertise.
        </p>

        <p>
          You might scope out spots to grab a tan on the beaches of Muri Lagoon, browse the artisan products at local craft markets, or explore the island’s locally loved cafes and restaurants. Learn more about Rarotonga’s history with a trip by the 19th-century Tinomana Palace or consider a trip to the flower-filled grounds of the Maire Nui Botanical Gardens before you head back to your hotel.
        </p>

        <Row>
          <Col lg={6}>
            <p>
              <StaticImage
                src="../images/tuk-tuk-people-smiling.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Tuk Tuk on Muri Beach"
              />
            </p>
          </Col>
        </Row>

        <hr />

        <h2>Personalised Cruise Ship Discovery tours</h2>

        <p>
          <StaticImage
            src="../images/cruise-ship.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Cruise Ship"
          />
        </p>

        <p>
          Our personalised half or full day tours are designed to let you explore, discover and immerse yourself in the beauty and culture of the Cook Islands.
        </p>

        <p>
          Contact us with your ideas and we will cater a full island experience which will leave you with everlasting memories.
        </p>

      </Col>
      <Col lg={4}>
        <p className="lead">Recent Press</p>
        <p>
          <a href="http://www.nzherald.co.nz/travel/news/article.cfm?c_id=7&objectid=11731227">
            <StaticImage
              src="../images/nz-herald.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://www.usatoday.com/story/travel/destinations/2017/04/01/cook-islands/99882942/">
            <StaticImage
              src="../images/large_USAToday_Logo_Square.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://kiwiliving.nz/holidays/mike-in-rarotonga">
            <StaticImage
              src="../images/kiwi-living.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        
      </Col>
    </Row>
    
    </Container>
      
  </Layout>
)

export default IndexPage
